import React, {useEffect, useState} from "react";
import {Checkbox, TextField} from "@fluentui/react";
import {Dropdown} from "@fluentui/react/lib/Dropdown";
import {IGlobalStorefrontSettings, IStorefrontSettings, PAYMENT_PROCESSOR_KEYS} from "../../models/Storefront";

interface IStorefrontOrderSettingsProps {
    settings: IStorefrontSettings;
    globalSettings?: IGlobalStorefrontSettings;
    onChange: (settings: IStorefrontSettings) => void;
}
const StorefrontOrderSettings: React.FC<IStorefrontOrderSettingsProps> = (props) => {
    
    const notesModeDropdownValues = () => { return [
        {key: '', text: 'Global Default'},
        {key: 'Enabled', text: 'True'},
        {key: 'Disabled', text: 'False'}]
    }
    return(<div style={{paddingTop: '2em'}}>
        <h3>Order Settings</h3>
        <div style={{paddingBottom: '1em'}}>
            <Checkbox label='Override Terms and Conditions' checked={props.settings.overrideTermsAndConditions}
                  onChange={(e, c) => props.onChange({...props.settings, overrideTermsAndConditions: c})}/>
        </div>
        <Dropdown options={notesModeDropdownValues()} selectedKey={props.settings.notesMode ?? ''}
                  onChange={(e, v) => props.onChange({...props.settings, notesMode: v?.key.toString()})}
                  label={'Use Notes'}
        />
        <TextField label='Notes Label' value={props.settings.notesLabel} 
                   onChange={(e, v) => props.onChange({...props.settings, notesLabel: v})}
                   placeholder={props.globalSettings?.notesLabel ?? 'Additional Order Information'}/>
        <TextField multiline label={'Notes Instructions (Markdown Supported)'} value={props.settings.notesInstructions}
                   onChange={(e, v) => props.onChange({...props.settings, notesInstructions: v})} placeholder={props.globalSettings?.notesInstructions}/>
        <TextField multiline
                   label={props.settings.paymentProcessor && props.settings.paymentProcessor != PAYMENT_PROCESSOR_KEYS.NONE ? 'Terms and Conditions (Text Only)' : 'Terms and Conditions (Markdown Supported)'}
                   value={props.settings.termsAndConditions} 
                   onChange={(e, v) => props.onChange({...props.settings, termsAndConditions: v})}/>
        <TextField type='email' label='Order Email' value={props.settings.orderEmail} onChange={(e, v) => props.onChange({...props.settings, orderEmail: v})}
                   placeholder={props.globalSettings?.orderEmail}/>
        <TextField multiline label='Customer Email Text' value={props.settings.customerEmailText}
                   onChange={(e, v) => props.onChange({...props.settings, customerEmailText: v})} placeholder={props.globalSettings?.customerEmailText}/>
        <TextField label='Customer Email Subject' value={props.settings.customerEmailSubject}
                   onChange={(e, v) => props.onChange({...props.settings, customerEmailSubject: v})}
                   placeholder={props.globalSettings?.customerEmailSubject}/>
    </div>)
}

export default StorefrontOrderSettings;